import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import './home.css'
import { Helmet } from 'react-helmet'
import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import ImageFader from 'src/components/home/imageFader'
import styled from 'styled-components'
import NavBlock from 'src/components/home/navblock'
import 'src/components/layout/resetcss.css'
import FullscreenCover from '../components/home/fullscreen-cover'

const MobileWrapper = styled.div`
  display: none;
  @media (max-aspect-ratio: 1/1) {
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
    background-image: linear-gradient(
      to bottom,
      #0e0f0b,
      #353836,
      #606364,
      #919394,
      #c5c5c7
    );
  }
`

const MobileInnerWrapper = styled.div`
  height: calc(100vh - 2rem);
`

const MobileBg = styled.div`
  background-image: linear-gradient(
    to bottom,
    #0e0f0b,
    #353836,
    #606364,
    #919394,
    #c5c5c7
  );
  min-height: 100vh;
  display: none;
  @media (max-aspect-ratio: 1/1) {
    display: block;
  }
`

const MobileTitleWrapper = styled.div`
  font-size: 2rem;
  text-align: center;
  line-height: 3rem;
  margin: auto;
  width: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
  h1 {
    font-size: 1.5rem;
    @media (min-width: 375px) {
      font-size: 2rem;
    }
    @media (min-width: 578px) {
      font-size: 2.5rem;
    }
  }
`

const MobileCoverWrapper = styled.div`
  padding-top: 2rem;
`

const StyledTitle = styled.h1`
  font-family: 'Cinzel', serif;
  @media (max-aspect-ratio: 1/1) {
    width: auto;
    color: #dedede;
    text-align: center;
    margin: 0.5rem 0;
  }
  text-align: center;
  font-weight: lighter;
`

const StyledNav = styled.nav`
  display: flex;
  /* position: fixed; */
  a {
    text-decoration: none;
  }
  a:nth-of-type(1) {
    h3 {
      padding: 0 1rem 0 0.5rem;
    }
  }
  a:nth-of-type(2) {
    h3 {
      padding: 0 1rem 0 1rem;
    }
  }
  a:nth-of-type(3) {
    h3 {
      padding: 0 0.5rem 0 1rem;
    }
  }
  @media (min-width: 321px) {
    h3 {
      margin: 0;
    }
    a:nth-of-type(1) {
      h3 {
        padding: 0 1.5rem 0 0.5rem;
      }
    }
    a:nth-of-type(2) {
      h3 {
        padding: 0 1.5rem 0 1.5rem;
      }
    }
    a:nth-of-type(3) {
      h3 {
        padding: 0 0.5rem 0 1.5rem;
      }
    }
  }
  @media (max-aspect-ratio: 1/1), (aspect-ratio: 1/1) {
    margin: 0.5rem 0;
    a {
      color: #484545;
    }
    /* position: absolute; */
    bottom: 0;
    flex-wrap: wrap;
    width: 100vw;
    font-size: 3rem;
  }
  margin-top: 1rem;
  margin: auto;
  justify-content: center;
`

const StyledSubtitle = styled.h3`
  font-family: 'Cinzel', serif;
  padding: 0 1rem;
  @media (min-aspect-ratio: 1/1) {
    padding: 0;
  }
  font-size: 1rem;
`

const TabletWrapper = styled.div`
  display: none;
  @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 8/5) {
    display: block;
    background-color: #1f2223;

  }
`
const DesktopWrapper = styled.div`
  display: none;
  background-color: #1f2223;
  @media (min-aspect-ratio: 8/5) {
    display: block;
  }
`

const DesktopContentFlex = styled.div`
  display: flex;
  height: 100vh;
  min-height: 600px;
`

const DesktopNavWrapper = styled.div`
  flex-basis: 35%;
`

const DesktopImageWrapper = styled.div`
  // height: 100%;
  // max-height: 100vh;
  flex-basis: 65%;
  padding: 2rem;
  .gatsby-image-wrapper {
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
`

export const data = graphql`
  query MyQuery {
    allFile(filter: { relativeDirectory: { regex: "/home/" } }) {
      nodes {
        relativeDirectory
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 800) {
            base64
            tracedSVG
            aspectRatio
            srcWebp
            srcSetWebp
            originalName
            src
            srcSet
          }
        }
      }
    }
  }
`

const FWImgMob = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: right;
  height: 100%;
`


const FWImg = styled(Img)`
  width: 100%;
  object-fit: contain;
  object-position: right;
  height: 100%;
`

const IndexPage = ({ data }) => {
  const photos = data.allFile.nodes
  const mobilePhotos = photos
    .filter(photo => photo.relativeDirectory === 'home/mobile')
    .map(photo => photo.childImageSharp.fixed)
  const desktopPhotos = photos
    .filter(photo => photo.relativeDirectory === 'home/desktop')
    .map(photo => photo.childImageSharp.fixed)
  return (
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Martin Jennings</title>
                <meta name="description" content="MARTIN JENNINGS has been making public sculpture in Britain for many years. His representations of great writers and poets are particularly well-known: John Betjeman at St Pancras Station, Philip Larkin in Hull, Charles Dickens in Portsmouth and George Orwell outside BBC Broadcasting House." />
            </Helmet>
      <MobileBg>
        <MobileTitleWrapper>
          <StyledTitle>Martin Jennings</StyledTitle>
        </MobileTitleWrapper>
        <MobileCoverWrapper>
          <FWImgMob src={mobilePhotos[0].src} srcSet={mobilePhotos[0].srcSet} />
          {/* <Img placeholderStyle={{
  opacity:  0,
}} fluid={mobilePhotos[0]}/> */}
        </MobileCoverWrapper>
        <StyledNav>
          <Link to="/about">
            <StyledSubtitle>About</StyledSubtitle>
          </Link>
          <Link to="/sculpture">
            <StyledSubtitle>Sculpture</StyledSubtitle>
          </Link>
          <Link to="/royalcoin">
            <StyledSubtitle>Royal coin</StyledSubtitle>
          </Link>
          <a href="https://martinjennings.co.uk/">
            <StyledSubtitle>Editions</StyledSubtitle>
          </a>
          <Link to="/contact">
            <StyledSubtitle>Contact</StyledSubtitle>
          </Link>
        </StyledNav>
      </MobileBg>
      {/* <MobileWrapper>
      <MobileInnerWrapper><MobileTitleWrapper>
      <StyledTitle>Martin Jennings</StyledTitle>
      </MobileTitleWrapper>
      <FullscreenCover img={mobilePhotos[0]} />
      <StyledNav>
      <Link to="/about">
      <StyledSubtitle>
      About
      </StyledSubtitle>
      </Link>
      <Link to="/sculpture">
      <StyledSubtitle>
      Sculpture
      </StyledSubtitle>
      </Link>
      <Link to="/contact">
      <StyledSubtitle>
      Contact
      </StyledSubtitle>
      </Link>
      </StyledNav></MobileInnerWrapper>
      </MobileWrapper> */}
      <TabletWrapper>
        <FullscreenCover img={desktopPhotos[0]} />
      </TabletWrapper>
      <DesktopWrapper>
        <DesktopContentFlex>
          <DesktopNavWrapper></DesktopNavWrapper>
          <DesktopImageWrapper>
            {/* <FWImg
              src={desktopPhotos[0].src}
              srcSet={desktopPhotos[0].srcSet}
            /> */}
            <FWImg fixed={desktopPhotos[0]}/>

            {/* <Img
             placeholderStyle={{
              opacity:  0,
            }}
              objectFit="contain"
              objectPosition="right 50%"
              fluid={desktopPhotos[0]}
            /> */}
          </DesktopImageWrapper>
        </DesktopContentFlex>
      </DesktopWrapper>
      <NavBlock />
    </>
  )
}

export default IndexPage
