import React, { useState, useEffect } from "react"
// import Img from "gatsby-image"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"

const CoverWrapper = styled.div`
@media (max-aspect-ratio: 1/1){
}
  /* min-height: -webkit-fill-available; */

  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
`

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
  img {
  }
`

const FWImg = styled.img`
    width: 100%;
`
const FullscreenCover = ({img}) => {
  return (
    <CoverWrapper>
      {/* <StyledImg 
      placeholderStyle={{
        opacity:  0,
      }}
      objectFit="contain"
      fluid={img} /> */}
      <FWImg src={img.src} srcSet={img.srcSet}/>
    </CoverWrapper>
  )
}

export default FullscreenCover
