import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import styled from "styled-components"
import { setTimeout } from "timers"
import media from 'styled-media-query';

const FaderWrapper = styled.div`
  height: ${props => (props.fullScreen ? "100vh" : "100%")};
  width: ${props => (props.fullScreen ? "100vw" : "100%")};
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
`

const StyledImg = styled(Img)`
    height: 100%;
    width: 100%;
    @media (min-width: 576px){
        height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  };
`

const SlidesWrapper = styled.div`
  height: 100%;
  width: 100%;
  transform: ${props => (props.transform ? props.transform : "transform(0,0)")};
  transition: all 0.2s;
  opacity: ${props => props.opacity};
  /* display: flex; */
`

const SlideWrapper = styled.div`
  height: 100%;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  opacity: ${props => props.opacity};
`

const fadeEffect = (setOpacity, setSlide, slide, delay) => {
  setTimeout(() => setSlide(slide), delay)
  setTimeout(() => setOpacity(1), delay)
}

const ImageFader = props => {
  const [index, setIndex] = useState(0)
  const [slide, setSlide] = useState(props.array[0])
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity(0)
      index === props.array.length ? setIndex(0) : setIndex(index + 1)
      fadeEffect(setOpacity, setSlide, props.array[index], props.opacityDelay)
    }, props.slideDelay)
    return () => {
      clearInterval(interval)
    }
  }, [index])

  return (
    <FaderWrapper fullScreen={props.fullScreen}>
      <SlidesWrapper transform={"translate(0,0)"} opacity={opacity}>
        <SlideWrapper number={props.number}>
          <StyledImg fluid={slide} 
  imgStyle={{
    objectFit: "cover",
    objectPosition: "50% 50%",
  }}/>
        </SlideWrapper>
      </SlidesWrapper>
    </FaderWrapper>
  )
}

ImageFader.defaultProps={
    slideDelay: 5000,
    opacityDelay: 200,

}

export default ImageFader

// const FaderWrapper = styled.div`
//   height: 100vh;
//   width: 100vw;
//   margin: 0;
//   padding: 0;
//   position: relative;
// `
// const SlideWrapper = styled.div`
//   height: 100%;
//   width: 100%;
//   opacity: ${props => (props.visible === true ? 1 : 0)};
//   position: absolute;
//   top: 0;
//   left: 0;
//   img {
//     height: 100%;
//     width: 100%;
//     object-fit: cover;
//   }
// `

// // array of images passed to container slideshow component
// // first image is set as fluid in top image
// // the second image is set as fluid in the bottom image
// // after a timeout the first image begins to fade out and the second image to fade in
// // after the fade duration the second image is props of topimage and the third image has to become prop of the bottomimage.
// // when all images have cycled, the first image has to become bottom image prop and it restarts.

// const nextSlide = (array, currentSlide) => {
//   if (currentSlide === parseInt(array.length) - 1)
//     return setTimeout(() => 0, delay)
//   return setTimeout(() => currentSlide + 1, delay)
// }

// const transition = ({props}) =>{
//     topImage !== bottomImage ? doTransition : null
// }

// const doTransition = ({props})=>{
//     startTransition(trDuration);
//     setTimeout(function(){
//         endTransition(trDuration);
//     }, delay)
// }

// const startTransition = ({props}) => {
//     setTopOpacity(0);
//     const secondaryDelay = delay - delay/5
//     setTimeout(function(){
//         setBottomOpacity(1)
//     }, secondaryDelay)
// }

// const endTransition = ({props}) => {
//     setTopImage(bottomImage)
//     setTopOpacity(1);
//     setBottomOpacity(0)
//     // const secondaryDelay = delay - delay/5
//     // setTimeout(function(){
//     // }, secondaryDelay)
// }

// const ImageFader = ({ props }) => {
//   const [topImage, setTopImage] = useState(false)
//   const [bottomImage, setBottomImage] = useState(false)
//   const [bottomOpacity, setBottomOpacity] = useState(0)
//   const [topOpacity, setTopOpacity] = useState(1)
//   const [currentSlide, setCurrentSlide] = useState(0)
//   useEffect(() => {
//     const topImage = setTopImage(props.topImage)
//     const bottomImage = setBottomImage(props.bottomImage)
//     const length = props.array.length

//     if (topImage !== bottomImage) {
//       setTimeout(() => {
//         setTopOpacity(0)
//       }, delay)

//       setTimeout(() => {
//         setBottomOpacity(1)
//       }, delay + transition)

//       window.addEventListener("mousemove", logMousePosition)
//       console.log("Created")
//       return () => {
//         console.log("Cleaned up")
//         window.removeEventListener("mousemove", logMousePosition)
//       }
//     }
//   }, [bottomImage])

//   const { timingFunction, delay, transition, alt } = props

//   return (
//     <FaderWrapper
//     delay={delay}
//     topImage={topImage}
//     bottomImage={bottomImage}
//     style={{ ...defaultStyle, ...{ position: "relative" } }}>
//       {topImage && (
//         <SlideWrapper opacity={topOpacity}>
//           <Img fluid={topImage} />
//         </SlideWrapper>
//       )}
//       {bottomImage && (
//         <SlideWrapper opacity={bottomOpacity}>
//           <Img fluid={bottomImage} />
//         </SlideWrapper>
//       )}
//     </FaderWrapper>
//   )
// }

// ImageFader.propTypes = {
//   topImage: PropTypes.string.isRequired,
//   bottomImage: PropTypes.string.isRequired,
//   alt: PropTypes.string,
//   timingFunction: PropTypes.string,
//   delay: PropTypes.number,
//   transition: PropTypes.number,
// }

// ImageFader.defaultProps = {
//   delay: 1000,
//   transition: 500,
//   timingFunction: "ease",
// }
