import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled, { ThemeProvider } from "styled-components"
import Img from "gatsby-image"
import shadow from "src/components/theme/shadows"
import { theme } from "src/components/theme/theme"
import BackgroundImage from "gatsby-background-image"
import media from "styled-media-query"

const MobileWrapper = styled.div`
  display: none;
  @media (max-aspect-ratio: 1/1), (aspect-ratio: 1/1) {
    /* display: block; */
  }
`
const TabletWrapper = styled.div`
  display: none;
  @media (min-aspect-ratio: 1/1) {
    display: block;
  }
`

const MobileTitleWrapper = styled.div`
  position: fixed;
  top: 1rem;
  font-size: 2rem;
  text-align: center;
  margin: auto;
  width: 100%;
`

const StyledTitle = styled.h1`
  font-family: "Cinzel", serif;
  @media (max-aspect-ratio: 1/1) {
    width: auto;
    color: #dedede;
    text-align: center;
  }
  @media (min-aspect-ratio: 1/1) {
    color: #b9b3ae;
    text-align: right;
    padding-bottom:1rem;
  }
  @media (min-aspect-ratio: 8/5) {
    top: 1rem;
    color: #dedede;
    font-size: 2rem;
    font-family: "Cinzel", serif;
    /* width: 100vw; */
    text-align: center;
  }
    @media (min-width: 380px){
        font-size: 2rem;
    }
  @media (min-width: 380px) and (min-height: 650px)  {
    font-size: 2.3rem;
  }
  @media (min-width: 576px) and (min-height: 650px) {
        font-size: 3rem;
  }
  @media (min-width: 800px) and (min-height: 650px) {
    font-size: 3.5rem;
  }
  @media (min-width: 1200px) and (min-height: 650px){
    font-size: 4rem;
  }
  @media (min-width: 1500px) and (min-height: 650px){
    font-size: 4rem;
  }
  text-align: center;
  font-weight: lighter;
`

const StyledNav = styled.nav`
  display: flex;
  position: fixed;
  a {
    text-decoration: none;
  }
  @media (max-aspect-ratio: 1/1), (aspect-ratio: 1/1) {
    a {
      color: #484545;
    }
    position: absolute;
    bottom: 0;
    flex-wrap: wrap;
    width: 100vw;
    font-size: 3rem;
    display: none;
  }
  @media (min-aspect-ratio: 1/1) {
    width: auto;
    top: 45%;
    flex-direction: column;
    text-align: right;
    bottom: unset;
    right: 2rem;
    transform: translate(0, -40%);
    a {
    color: #b9b3ae;
    }
  }
  @media (min-aspect-ratio: 5/4){
    right: 3rem;

  }
  @media (min-aspect-ratio: 8/6){
    right: 4.5rem;

  }
  @media (min-aspect-ratio: 9/6){
    right: 5.5rem;

  }
  @media (min-aspect-ratio: 9/6) and (max-aspect-ratio: 8/5) {
    right: 6.5rem;

  }
  @media (min-aspect-ratio: 8/5) {
    top: 45%;
    text-align: center;
    bottom: unset;
    left: 2rem;
    width: 35%;
    margin: 0;
    a{
        color: #b9b3ae;
    }
  }
  a{
    @media (min-width: 380px) and (max-aspect-ratio: 8/5){
        color: #484545;
    }

  @media (min-width: 350px) {
    flex-wrap: nowrap;
  }
  @media (min-width: 380px) {
    font-size: 1.2rem;
  }
  @media (min-width: 576px) {
    font-size: 1.5rem;
  }
  @media (min-width: 700px) {
    font-size: 1.8rem;
    color: #dedede;
  }
  @media (min-width: 1200px) {
    font-size: 2rem;
  }
  @media (min-width: 1500px) {
    }
  }
  margin-top: 1rem;
  margin: auto;
  justify-content: center;
`

const StyledSubtitle = styled.h3`
  font-family: "Cinzel", serif;
  padding: 0 1rem;
  font-size: 1rem;
  @media (min-aspect-ratio: 1/1) {
      padding: 0.25rem 0;
      font-size: 1.4rem;
  }
`

const NavBlock = () => {
  return (
    <div>
      <MobileWrapper>
        <MobileTitleWrapper>
          <StyledTitle>Martin Jennings</StyledTitle>
        </MobileTitleWrapper>
      </MobileWrapper>
      <StyledNav>
        <TabletWrapper>
          <StyledTitle>
            <div>Martin</div>
            <div>Jennings</div>
          </StyledTitle>
        </TabletWrapper>
          <Link to="/about">
        <StyledSubtitle>
          About
        </StyledSubtitle>
          </Link>
          <Link to="/sculpture">
        <StyledSubtitle>
          Sculpture
        </StyledSubtitle>
          </Link>
          <Link to="/royalcoin">
        <StyledSubtitle>
          Royal Coin
        </StyledSubtitle>
          </Link>
          <a href="https://martinjennings.co.uk/">
        <StyledSubtitle>
          Editions
        </StyledSubtitle>
          </a>
          <Link to="/contact">
        <StyledSubtitle>
          Contact
        </StyledSubtitle>
          </Link>
      </StyledNav>
    </div>
  )
}

export default NavBlock
